import React, { Fragment } from "react"
import { Helmet } from "react-helmet"
import { graphql } from "gatsby"
import { PageIntro } from "src/components/PageIntro"
import { BigBlock } from "src/components/BigBlock"
import { SearchBar } from "src/components/SearchBar"
import { Features } from "src/components/Features"
import { JumboBanner } from "src/components/JumboBanner"
import { Customers } from "src/components/Customers"
import { OffersSlider } from "src/components/OffersSlider"
import { SlideProps } from "src/components/Customers/Slide"
import { Header } from "src/components/Header"
import SEO from "src/components/SEO"
import { getFluidImageSrc, getFixedImageSrc } from "src/utils/images"
import InViewMonitor from "react-inview-monitor"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { PageCTA } from "src/components/PageCTA"

const HomePage: React.FC<any> = ({ data: { mdx } }) => {
  const {
    metaTitle,
    metaDescription,
    intro: { title, subtitle, hasSideImage, content },
    headerImage,
    videoFileName,
    videoFileNameMobile,
    bigBlocks,
    featuresTitle,
    featuresSubtitle,
    featureHasTexture,
    featuresCards,
    jumboBannerTitle,
    jumboBannerSubtitle,
    jumboBannerBackgroundImage,
    jumboBannerLinkToPageId,
    customerReviewsTitle,
    customerReviewsSubtitle,
    customerReviews,
  } = mdx.frontmatter

  console.log("jumboBannerBackgroundImage :", jumboBannerBackgroundImage)
  return (
    <Fragment>
      <SEO title={metaTitle} description={metaDescription} />

      <Helmet
        bodyAttributes={{
          class: "is-home",
        }}
      />

      <Header
        backgroundImageUrl={headerImage.publicURL}
        videoName={videoFileName}
        videoNameMobile={videoFileNameMobile}
      />

      <InViewMonitor classNameNotInView="animate-box" classNameInView="animated fadeInUp d-md-none">
        <PageCTA />
      </InViewMonitor>

      <InViewMonitor classNameNotInView="animate-box" classNameInView="animated moveUp bring-top">
        <SearchBar />
      </InViewMonitor>

      <InViewMonitor classNameNotInView="animate-box" classNameInView="animated fadeInUp">
        <PageIntro title={title} subtitle={subtitle} hasSideImage={hasSideImage}>
          {content && content.length ? content : <MDXRenderer>{mdx.body}</MDXRenderer>}
        </PageIntro>
      </InViewMonitor>

      {bigBlocks.map(({ description, iconName, linkTo, title, variant, image }, idx) => (
        <BigBlock
          key={idx}
          title={title}
          iconName={iconName}
          variant={variant}
          imageURL={getFluidImageSrc(image)}
          description={description}
          linkToPageId={linkTo}
        />
      ))}

      <InViewMonitor classNameNotInView="animate-box" classNameInView="animated fadeInUp">
        <Features
          title={featuresTitle}
          subtitle={featuresSubtitle}
          hasTexture={featureHasTexture}
          featuresCards={featuresCards.map(card => ({
            imageUrl: getFixedImageSrc(card.backgroundImage),
            title: card.label,
            linkToPageId: card.linkToPageId,
            iconName: card.iconName,
            orderMd: card.orderMd,
          }))}
        />
      </InViewMonitor>

      <InViewMonitor classNameNotInView="animate-box" classNameInView="animated fadeInUp d-none d-lg-block">
        <JumboBanner
          title={jumboBannerTitle}
          subtitle={jumboBannerSubtitle}
          imageUrl={getFixedImageSrc(jumboBannerBackgroundImage)}
          linkToPageId={jumboBannerLinkToPageId}
        />
      </InViewMonitor>

      <InViewMonitor classNameNotInView="animate-box" classNameInView="animated fadeInUp d-none d-lg-block">
        <Customers
          title={customerReviewsTitle}
          subtitle={customerReviewsSubtitle}
          reviews={
            customerReviews.map(review => ({
              author: review.author,
              text: review.text,
              stars: review.rating,
            })) as SlideProps[]
          }
        />
      </InViewMonitor>
      <InViewMonitor classNameNotInView="animate-box" classNameInView="animated fadeInUp">
        <OffersSlider />
      </InViewMonitor>
    </Fragment>
  )
}

export default HomePage

export const query = graphql`
  query HomePage($locale: String!, $title: String!) {
    mdx(frontmatter: { title: { eq: $title } }, fields: { locale: { eq: $locale } }) {
      frontmatter {
        metaTitle
        metaDescription
        intro {
          title
          subtitle
          lead
          hasSideImage
          content
        }
        headerImage {
          publicURL
          childImageSharp {
            fluid(maxWidth: 1900) {
              ...GatsbyImageSharpFluid
              srcWebp
            }
          }
        }
        videoFileName
        videoFileNameMobile
        bigBlocks {
          title
          iconName
          variant
          image {
            publicURL
            childImageSharp {
              fluid(maxWidth: 2000) {
                ...GatsbyImageSharpFluid
                srcWebp
              }
              fixed(width: 200, height: 300, quality: 80) {
                ...GatsbyImageSharpFixed
                srcWebp
              }
            }
          }
          description
          linkTo
        }
        featuresTitle
        featuresSubtitle
        featureHasTexture
        featuresCards {
          label
          iconName
          orderMd
          linkToPageId
          backgroundImage {
            publicURL
            childImageSharp {
              fluid(maxWidth: 600) {
                ...GatsbyImageSharpFluid
                srcWebp
              }
              fixed(width: 600, height: 300, quality: 80) {
                ...GatsbyImageSharpFixed
                srcWebp
              }
            }
          }
        }
        jumboBannerTitle
        jumboBannerSubtitle
        jumboBannerBackgroundImage {
          publicURL
          childImageSharp {
            fluid(maxWidth: 1680) {
              ...GatsbyImageSharpFluid
              srcWebp
            }
            fixed(width: 1680, height: 780, quality: 80) {
              ...GatsbyImageSharpFixed
              srcWebp
            }
          }
        }
        jumboBannerLinkToPageId
        customerReviewsTitle
        customerReviewsSubtitle
        customerReviews {
          author
          rating
          text
        }
      }
      body
    }
  }
`
