import React from "react"
import { Container, Jumbotron, Button } from "src/ui"
import useTranslations from "../useTranslations"
import useInternalLinks from "../useInternalLinks"
import { Link } from "gatsby"

interface Props {
  title: string
  subtitle?: string
  imageUrl?: string
  linkToPageId: string
}

export const JumboBanner: React.FC<Props> = ({ title, subtitle, imageUrl, linkToPageId }) => {
  const read_more = useTranslations("more")
  const linkFromPageId = useInternalLinks(linkToPageId)
  return (
    <section className="section">
      <Jumbotron className="shadow-lg">
        <Container>
          <div className="jumbotron-inner text-center">
            <h1 className="text-secondary">{title}</h1>
            <p className="lead text-white p0 pb-4 mb-0">{subtitle}</p>
            {/* <Button size="lg" color="secondary" >
              {read_more}
            </Button> */}
            <Link to={linkFromPageId} className="btn btn-lg btn-secondary text-white">
              {read_more}
            </Link>
          </div>
        </Container>
        <div className="cover-img" style={{ backgroundImage: `url(${imageUrl})` }}></div>
      </Jumbotron>
    </section>
  )
}
