import React from "react"
import classNames from "classnames"
import { Container, Row, Col } from "src/ui"

interface Props {
  title: string
  lead?: string
  subtitle?: string
  hasSideImage?: boolean
  className?: string
}

export const PageIntro: React.FC<Props> = ({ title, subtitle, lead, children, className, hasSideImage }) => {
  const wrapperClassName = classNames("section section-default", {
    "bg-palm": hasSideImage,
    className: Boolean(className),
  })

  return (
    <section className={wrapperClassName}>
      <Container>
        <Row>
          <Col md="9" xl={{ offset: 1 }}>
            <h1 className="text-warning section-title">{title}</h1>
            {subtitle && <h2 className="text-secondary display-1">{subtitle}</h2>}
            {lead && <p className="lead py-3 text-primary">{lead}</p>}
            <div className="section-body">{children}</div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}
