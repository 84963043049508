import React from "react"
import classNames from "classnames"
import { Card, CardImg, CardImgOverlay, CardBody, CardTitle } from "src/ui"
import { Link } from "gatsby"
import useInternalLinks from "../useInternalLinks"

export interface FeatureItemProps {
  imageUrl?: string
  title: string
  iconName: string
  orderMd?: number
  linkToPageId: string
}

export const FeatureItem: React.FC<FeatureItemProps> = ({ imageUrl, title, iconName, linkToPageId }) => {
  const containerCss = classNames("feature-item card-container overflow-hidden", {
    "shadow-lg": Boolean(imageUrl),
  })

  const cardCss = classNames("text-center h-100 w-100", {
    "text-white": Boolean(imageUrl),
    "text-primary": !Boolean(imageUrl),
  })

  const cardImageOverlayCss = classNames("d-flex align-items-center ", {
    "card-img-overlay-primary": Boolean(imageUrl),
  })

  const icon = iconName ? require(`src/ui/img/${iconName}`) : null
  const linkFromPageId = useInternalLinks(linkToPageId)

  return (
    <div className={containerCss}>
      <Card className={cardCss}>
        {imageUrl && <CardImg width="100%" src={imageUrl} alt={title} />}
        <CardImgOverlay className={cardImageOverlayCss}>
          <CardBody>
            <div className="feature-item-img p-3">
              {/* icon svg loaded from 'src/ui/img/'  */}
              {icon && <img src={icon} alt="" className="svg" />}
            </div>
            <CardTitle tag="h3" className="mb-0">
              {title}
            </CardTitle>
            <Link to={linkFromPageId} className="stretched-link" />
          </CardBody>
        </CardImgOverlay>
      </Card>
    </div>
  )
}
