import React from "react"
import classNames from "classnames"
import { Link } from "gatsby"
import useInternalLinks from "../useInternalLinks"
import useTranslations from "../useTranslations"
import InViewMonitor from "react-inview-monitor"

interface Props {
  title: string
  iconName: string
  variant: "aqua" | "green"
  imageURL?: string
  description: string
  linkToPageId: string
}

export const BigBlock: React.FC<Props> = ({ title, iconName, variant, imageURL, description, linkToPageId }) => {
  const read_more = useTranslations("more")

  const sectionCssClasses = classNames("section-big-block", {
    "big-block-acqua big-block-left": variant === "aqua",
    "big-block-green big-block-right": variant === "green",
  })

  const icon = iconName ? require(`src/ui/img/${iconName}`) : null
  const linkFromPageId = useInternalLinks(linkToPageId)

  return (
    <InViewMonitor classNameNotInView="animate-box" classNameInView="animated fadeInUp">
      <section className={sectionCssClasses}>
        <div className="big-block-image">
          <div
            className="image-as-background"
            style={{
              backgroundImage: `url(${imageURL})`,
            }}
          ></div>
        </div>
        <div className="big-block-info">
          <div className="big-block-info-inner text-center text-white">
            <div className="big-block-img p-2">
              <img src={icon} alt="" />
            </div>
            <h2>{title}</h2>
            <p className="mb-3 py-3 text-white-80">{description}</p>
            <Link to={linkFromPageId} className="btn btn-lg btn-secondary text-white">
              {read_more}
            </Link>
          </div>
        </div>
      </section>
    </InViewMonitor>
  )
}
