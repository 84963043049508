import React from "react"

export interface SlideProps {
  text: string
  author: string
  stars: 1 | 2 | 3 | 4 | 5
}

export const Slide: React.FC<SlideProps> = ({ text, author, stars }) => {
  return (
    <div className="px-2 py-1 h-100">
      <div className="review-inner p-5 shadow-sm h-100">
        <blockquote className="mb-0">
          <p className="text-muted">{text}</p>
          <div className="d-flex justify-content-between">
            <div className="customer-name text-dark font-weight-bold">{author}</div>
            <div>
              {[...Array(stars).keys()].map(star => (
                <span key={star}>
                  <StarIcon />
                </span>
              ))}
            </div>
          </div>
        </blockquote>
      </div>
    </div>
  )
}

const StarIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="17.143" viewBox="0 0 18 17.143">
    <path
      id="Path_568"
      data-name="Path 568"
      d="M9.042,0l2.113,6.5H18l-5.577,4.053,2.2,6.587L9.042,13.089,3.465,17.143l2.113-6.587L0,6.5H6.845Z"
      fill="#fcc329"
    />
  </svg>
)
