import React from "react"
import { Container } from "src/ui"
import useTranslations from "../useTranslations"
import { getLocalizedSubmitUrl } from "src/components/Navigation/utils"
import { LocaleContext } from "../layout"
import { InternalLink } from "../InternalLink"
import { sendTagManagerEvent } from "src/utils/googleTagManager"

export const PageCTA: React.FC = () => {
  const { locale } = React.useContext(LocaleContext)
  const get_a_quote = useTranslations("get_a_quote")
  const call_us = useTranslations("call_us")
  const get_offers = useTranslations("discover_offers")
  const where = useTranslations("where")
  const enquiries = useTranslations("site_alert_summer_title")

  return (
    <div className="section text-center">
      <Container>
        <a
          href="tel:+390861713888"
          className="btn btn-lg btn-success btn-block text-white mb-3"
          onClick={() => {
            sendTagManagerEvent({
              event: "editaTelefono",
              eventCategory: "Telefono",
              eventAction: "Click",
              eventLabel: "+390861713888",
              eventValue: "7",
              eventNhi: "FALSE",
              eventFacebook: "Telefono Click",
            })
          }}
        >
          {call_us}
        </a>
        <a
          href={getLocalizedSubmitUrl(locale)}
          className="btn btn-lg btn-success btn-block text-white mb-3"
          target="_blank"
          rel="noopener"
          onClick={() => {
            sendTagManagerEvent({
              event: "editaBooking",
              eventCategory: "Booking",
              eventAction: "Click",
              eventLabel: window?.location.href,
              eventValue: "1",
              eventNhi: "FALSE",
              eventFacebook: "Booking Click",
            })
          }}
        >
          {get_a_quote}
        </a>
        <InternalLink
          pageId="posizione-dintorni/dove-siamo"
          className="btn btn-lg btn-success btn-block text-white mb-3"
        >
          {where}
        </InternalLink>
        <InternalLink
          pageId="richieste-prenotazione/offerte"
          className="btn btn-lg btn-success btn-block text-white mb-3"
        >
          {get_offers}
        </InternalLink>
        <InternalLink
          pageId="richieste-prenotazione/richiesta"
          className="btn btn-lg btn-success btn-block text-white mb-3"
        >
          {enquiries}
        </InternalLink>
        <a
          href="https://wa.me/393911369580"
          className="btn btn-lg btn-success btn-block text-white"
          onClick={() => {
            sendTagManagerEvent({
              event: "editaWhatsapp",
              eventCategory: "Whatsapp",
              eventAction: "Click",
              eventLabel: "+393911369580",
              eventValue: "7",
              eventNhi: "FALSE",
              eventFacebook: "Whatsapp Click",
            })
          }}
        >
          WhatsApp
        </a>
      </Container>
    </div>
  )
}
