import React, { useState } from "react"
import { it as localeIt, enGB as localeGb, de as localeDe, fr as localeFr } from "date-fns/locale"
import { DateRangePicker, START_DATE, END_DATE } from "react-nice-dates"
import { PageContext } from "ExtendiApp"

import { LocaleContext } from "../layout"
import useTranslations from "../useTranslations"
import { Container, Col, Row, Input, Label, Button } from "src/ui"
import { FormGroup } from "reactstrap"
import { formatDateToShow, openingDate, closingDate } from "src/utils/date"
import { sendTagManagerEvent } from "src/utils/googleTagManager"

const getLocale = (locale: PageContext["locale"]) => {
  switch (locale) {
    case "de":
      return localeDe
    case "it":
      return localeIt
    case "fr":
      return localeFr
    default:
      return localeGb
  }
}

const formatDateInput = (dateString: Date) => {
  if (!dateString) {
    return ""
  }
  const date = new Date(dateString)
  const day = `${date.getDate()}`.padStart(2, "0")
  const month = `${date.getMonth() + 1}`.padStart(2, "0")

  return `${day}/${month}/${date.getFullYear()}`
}

const getLocalizedSubmitUrl = (locale: PageContext["locale"]) => {
  const languageParameter = locale === "en" ? "UK" : locale.toLowerCase()
  const baseUrl = `https://secure.iperbooking.net/be/${languageParameter}/residence-hotel-paradiso-teramo/`
  return baseUrl
}

export const SearchBar = () => {
  const { locale: pageLanguage } = React.useContext(LocaleContext)
  const localeFileToUse = getLocale(pageLanguage)
  const [dateFrom, setDateFrom] = useState(openingDate)
  const [dateTo, setDateTo] = useState<Date | undefined>()
  const [adults, setAdults] = useState(2)
  const [children, setChildren] = useState(0)
  const [childrenAges, setChildrenAges] = useState<number[]>([])

  const [inputStartDateTouched, setInputStartDateTouched] = useState(false)

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    if (!dateFrom || !dateTo || !adults) {
      e.preventDefault()
    }

    sendTagManagerEvent({
      event: "editaBooking",
      eventCategory: "Booking",
      eventAction: "Submit",
      eventLabel: window?.location.href,
      eventValue: "1",
      eventNhi: "FALSE",
      eventFacebook: "Booking Submit",
    })
  }

  return (
    <section className="section p-0 d-none d-md-block">
      <Container>
        <form
          className="quick-reservation position-relative shadow-lg bg-white"
          action={getLocalizedSubmitUrl(pageLanguage)}
          method="POST"
          target="_blank"
          onSubmit={handleSubmit}
        >
          <input type="hidden" name="idHotel" value="1464" />
          <input type="hidden" name="NumeroCamere" value="1" />
          <input type="hidden" name="IdTrattamento" value="2" />
          <input type="hidden" name="Arrivo" value={formatDateInput(dateFrom)} />
          <input type="hidden" name="Partenza" value={dateTo ? formatDateInput(dateTo) : ""} />
          <input type="hidden" name="Camera_1_Adulti" value={adults} />
          <input type="hidden" name="Camera_1_Bambini" value={children} />
          {childrenAges && childrenAges.length
            ? childrenAges.map((age, idx) => (
                <input key={`input-chil-${idx}`} type="hidden" name={`Camera_1_EtaBambino_${idx + 1}`} value={age} />
              ))
            : null}

          <DateRangePicker
            startDate={dateFrom}
            endDate={dateTo}
            onStartDateChange={val => {
              setDateFrom(val)
              setInputStartDateTouched(true)
            }}
            onEndDateChange={val => {
              setDateTo(val)
              setInputStartDateTouched(true)
            }}
            locale={localeFileToUse}
            minimumDate={openingDate}
            maximumDate={closingDate}
          >
            {({ startDateInputProps, endDateInputProps, focus }) => {
              return (
                <Row noGutters>
                  <Col>
                    <FormGroup>
                      <Label htmlFor="from">{useTranslations("when")}</Label>
                      <div className="d-flex align-items-center">
                        <Input
                          {...startDateInputProps}
                          ref={undefined}
                          id="from"
                          className={
                            "bg-white input-date" + (focus === START_DATE ? " border-bottom border-info -focused" : "")
                          }
                          placeholder={useTranslations("arrival")}
                          autoComplete="off"
                          value={
                            !inputStartDateTouched
                              ? undefined
                              : formatDateToShow(startDateInputProps.value, pageLanguage)
                          }
                          required
                        />
                        {dateTo ? <div className="px-2 text-aqua">/</div> : null}
                        <Input
                          {...endDateInputProps}
                          ref={undefined}
                          id="to"
                          className={
                            "bg-white input-date" + (focus === END_DATE ? " border-bottom border-info -focused" : "")
                          }
                          placeholder={useTranslations("departure")}
                          value={formatDateToShow(endDateInputProps.value, pageLanguage)}
                          autoComplete="off"
                          required
                        />
                      </div>
                    </FormGroup>
                  </Col>

                  <Col>
                    <FormGroup>
                      <Label htmlFor="adults">{useTranslations("adults")}</Label>
                      <Input
                        type="number"
                        id="adults"
                        value={adults}
                        min={1}
                        max={8}
                        step={1}
                        onChange={({ currentTarget: { value } }) => setAdults(parseInt(value, 10))}
                        required
                      />
                    </FormGroup>
                  </Col>

                  <Col>
                    <FormGroup>
                      <Label htmlFor="children">{useTranslations("_children")}</Label>
                      <Input
                        type="number"
                        id="children"
                        value={children || "0"}
                        onChange={({ currentTarget: { value } }) => {
                          const inputValue = parseInt(value, 10)
                          setChildren(inputValue)
                          setChildrenAges(state => {
                            return state && inputValue > 0
                              ? inputValue > state.length
                                ? [...state, 0]
                                : [...state.slice(0, inputValue)]
                              : [0]
                          })
                        }}
                        min={0}
                        max={7}
                        step={1}
                      />
                    </FormGroup>
                    {childrenAges && childrenAges.length ? (
                      <div className="children-age shadow">
                        {childrenAges.map((_, index) => (
                          <div key={index} className="d-flex align-items-center justify-content-between mb-2">
                            <Label htmlFor="children" className="mb-0 pr-3">
                              {useTranslations(`ageChildren_${index}`)}
                            </Label>
                            <Input
                              type="number"
                              id="children"
                              value={
                                childrenAges && childrenAges[index] && childrenAges[index] > 0
                                  ? childrenAges[index]
                                  : ""
                              }
                              onChange={({ currentTarget: { value } }) => {
                                const inputAge = parseInt(value, 10)
                                if (inputAge > 14) {
                                  return
                                }
                                setChildrenAges(state =>
                                  state && state.length
                                    ? [...state.map((age, idx) => (idx === index ? inputAge : age))]
                                    : [inputAge]
                                )
                              }}
                              required
                              min={1}
                              max={14}
                              step={1}
                            />
                          </div>
                        ))}
                      </div>
                    ) : null}
                  </Col>

                  <Col md={{ size: 4 }} className="d-flex">
                    <Button type="submit" block className="text-white">
                      {useTranslations("get_a_quote")}
                    </Button>
                  </Col>
                </Row>
              )
            }}
          </DateRangePicker>
        </form>
      </Container>
    </section>
  )
}
