import React from "react"
import classNames from "classnames"
import { Container, Row, Col } from "src/ui"
import { FeatureItemProps, FeatureItem } from "./FeatureItem"

interface Props {
  title: string
  subtitle?: string
  className?: string
  hasTexture?: boolean
  featuresCards: FeatureItemProps[]
}

export const Features: React.FC<Props> = ({ title, subtitle, className, hasTexture, featuresCards }) => {
  const wrapperClassName = classNames("section section-default section-full section-inverse bg-secondary", {
    "has-texture": hasTexture,
    className: Boolean(className),
  })
  return (
    <section className={wrapperClassName}>
      <Container>
        <div className="section-title text-center">
          <h1 className="text-darkbrown">{title}</h1>
          <h2 className="text-primary-50 display-1">{subtitle}</h2>
        </div>
        <Row className="features mt-4">
          {featuresCards && featuresCards.length
            ? featuresCards.map((featureItem, idx) => (
                <Col key={idx} md={{ size: 6, order: featureItem.orderMd }}>
                  <FeatureItem {...featureItem} />
                </Col>
              ))
            : null}
        </Row>
      </Container>
    </section>
  )
}
