import React from "react"
import Slider, { Settings as SlickSettings } from "react-slick"
import { Container } from "src/ui"
import { SlideProps, Slide } from "./Slide"

interface Props {
  title: string
  subtitle?: string
  reviews: SlideProps[]
}

export const Customers: React.FC<Props> = ({ title, subtitle, reviews }) => {
  const sliderSettings: SlickSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    arrows: false,
  }

  return (
    <section className="section section-default">
      <Container>
        <div className="section-title text-center">
          <h1 className="text-warning">{title}</h1>
          <h2 className="text-secondary display-1">{subtitle}</h2>
        </div>

        <Slider {...sliderSettings} className="customers-slider">
          {reviews.map((review, index) => (
            <Slide key={index} {...review} />
          ))}
        </Slider>
      </Container>
    </section>
  )
}
